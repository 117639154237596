<template>
  <div>
    <shipblu-card :cardLoading="packageLoading" class="p-5">
      <div class="flex mb-3 justify-between">
        <h3>{{$t('Packaging')}}</h3>
        <vs-button 
          v-if="orderPackages.length < 4 && (orderData.status === 'created' || (orderData.status === 'confirmed_to_ship' && $store.state.AppActiveUser.userRole === 'fc-operations-supervisor'))" 
          @click="addPackage('Add')" color="primary" class="py-2 px-3">{{ $t('Add packaging') }}</vs-button>
      </div>

      <shipblu-table
        :sst="true"
        :hideHeader="true"
        multiple
        v-model="selected"
        :data="orderPackages"
      >
        <template slot="thead">
          <shipblu-th>{{$t('Package')}}</shipblu-th>
          <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'merchant'">{{$t('Actions')}}</shipblu-th>
          <shipblu-th v-if="orderData.status === 'confirmed_to_ship' && $store.state.AppActiveUser.userRole === 'fc-operations-supervisor'">{{$t('Actions')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <shipblu-td :data="data[indextr].requestID">
              {{ data[indextr].package_size.name }}
            </shipblu-td>

            <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'merchant'">
              <span @click="addPackage('Edit', data[indextr])" 
                :disabled="!orderInfoEditable" :class="orderData.status === 'created' ? 'text-primary' : 'disabled'">
                <feather-icon
                  icon="EditIcon"
                  svgClasses="h-6 w-6"
                  class="mr-2"
                />
              </span>
              <span @click="$emit('deleteProduct', data[indextr])" 
                :disabled="!orderInfoEditable" :class="orderData.status === 'created' ? 'text-danger' : 'disabled'">
                <feather-icon
                  icon="Trash2Icon"
                  svgClasses="h-6 w-6"
                />
              </span>
            </shipblu-td>
            <shipblu-td v-if="orderData.status === 'confirmed_to_ship' && $store.state.AppActiveUser.userRole === 'fc-operations-supervisor'">
              <span @click="$emit('deleteProduct', data[indextr])" 
                class="text-danger">
                <feather-icon
                  icon="Trash2Icon"
                  svgClasses="h-6 w-6"
                />
              </span>
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
    </shipblu-card>

    <add-order-packages :orderPackages="orderPackages" :packageLoading="packageLoading" :packageObj="packageObj" :packageModal="packageModal" @loadOrder="loadOrder" :type="packageType" @packageModal="packageModal = $event"/>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import ShipbluTable from '@/layouts/components/ShipBluTable.vue'
import ShipbluTr from '@/layouts/components/ShipBluTr.vue'
import ShipbluTh from '@/layouts/components/ShipBluTh.vue'
import ShipbluTd from '@/layouts/components/ShipBluTd.vue'
import AddOrderPackages from '../../../../fcOperationsSupervisor/components/AddOrderPackages.vue'

export default {
  props: ['packageLoading', 'selected', 'orderPackages', 'orderData', 'orderInfoEditable'],
  data () {
    return {
      packageModal: false,
      packageObj: {},
      packageType: 'Add'
    }
  },
  methods: {
    addPackage (type, data) {
      this.packageModal = true
      this.packageType = type
      this.packageObj = data
    },
    loadOrder () {
      this.$emit('loadOrder')
    }
  },
  components: {
    ShipbluCard,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddOrderPackages
  }
}
</script>